import Admission from "../../../api/admission/admission";
import fileDownload from "js-file-download";
import router from "@/router";

const admission = new Admission();
const admissionModule = {
  namespaced: true,
  state: () => ({
    ip: null,
    load: false,
    lookups: {},
    item: null,
    needTraning: null,
    admissionData: null,
    preview: false,
    applicantForm: {},
    dataForm: {},
    underGradutetabsData: [
      { name: "nationality", id: 1, type: 1, active: false },
      { name: "personal_info", id: 2, type: 1, active: false },
      { name: "contact_info", id: 3, type: 1, active: false },
      { name: "parent_info", id: 4, type: 1, active: false },
      { name: "academic_data", id: 5, type: 1, active: false },
      { name: "documents", id: 6, type: 1, active: false },
      { name: "personal_preference", id: 7, type: 1, active: false },
      { name: "payment", id: 8, type: 1, active: false },
    ],
    transfertabsData: [
      { name: "nationality", id: 1, type: 1, active: false },
      { name: "personal_info", id: 2, type: 1, active: false },
      { name: "contact_info", id: 3, type: 1, active: false },
      { name: "parent_info", id: 4, type: 1, active: false },
      { name: "academic_data", id: 5, type: 1, active: false },
      { name: "previous_university_info", id: 6, type: 1, active: false },
      { name: "documents", id: 7, type: 1, active: false },
      { name: "personal_preference", id: 8, type: 1, active: false },
      { name: "payment", id: 9, type: 1, active: false },
    ],
    postGraduteDiplomatabsData: [
      { name: "nationality", id: 1, type: 1, active: false },
      { name: "personal_info", id: 2, type: 1, active: false },
      { name: "contact_info", id: 3, type: 1, active: false },
      { name: "documents", id: 6, type: 1, active: false },
      { name: "personal_preference", id: 7, type: 1, active: false },
      { name: "bachelor_degree", id: 5, type: 1, active: false },
      { name: "payment", id: 8, type: 1, active: false },
    ],
    postGraduteMastertabsData: [
      { name: "nationality", id: 1, type: 1, active: false },
      { name: "personal_info", id: 2, type: 1, active: false },
      { name: "contact_info", id: 3, type: 1, active: false },
      { name: "documents", id: 7, type: 1, active: false },
      { name: "personal_preference", id: 8, type: 1, active: false },
      { name: "bachelor_degree", id: 5, type: 1, active: false },
      { name: "diploma_degree", id: 6, type: 1, active: false },
      { name: "payment", id: 9, type: 1, active: false },
    ],
    postGradutePHDtabsData: [
      { name: "nationality", id: 1, type: 1, active: false },
      { name: "personal_info", id: 2, type: 1, active: false },
      { name: "contact_info", id: 3, type: 1, active: false },
      { name: "documents", id: 8, type: 1, active: false },
      { name: "personal_preference", id: 9, type: 1, active: false },
      { name: "bachelor_degree", id: 5, type: 1, active: false },
      { name: "diploma_degree", id: 6, type: 1, active: false },
      { name: "master_degree", id: 7, type: 1, active: false },
      { name: "payment", id: 10, type: 1, active: false },
    ],
  }),
  getters: {
    ip: (state) => state.ip,
    tabsData(state) {
      if (router.currentRoute.params.type == 1) {
        return state.underGradutetabsData;
      } else if (router.currentRoute.params.type == 3) {
        return state.transfertabsData;
      } else if (router.currentRoute.params.type == 4) {
        return state.postGraduteDiplomatabsData;
      } else if (router.currentRoute.params.type == 5) {
        return state.postGraduteMastertabsData;
      } else if (router.currentRoute.params.type == 6) {
        return state.postGradutePHDtabsData;
      }
    },
    load: (state) => {
      if (Object.keys(state.load).length == 0) return false;
      return state.load;
    },
    applicantForm: (state) => state.applicantForm["applicantForm"] || {},
    preview: (state) => state.preview,
    dataForm: (state) => state.dataForm,
    lookups: (state) => state.lookups,
    item: (state) => state.item,
    needTraning: (state) => state.needTraning,
    admissionData: (state) => state.admissionData,
    openedAdmission(state) {
      if (state.admissionData) {
        return state.admissionData && state.admissionData.admissions;
      }
    },
    admissionTerms(state) {
      if (state.admissionData) {
        return state.admissionData && state.admissionData.admission_terms;
      }
    },
    admissionCountries(state) {
      if (state.admissionData) {
        return state.admissionData && state.admissionData.countries;
      }
    },
  },
  mutations: {
    SET_IP(state, data) {
      state.ip = data;
    },
    UPDATE_TABS(state, [name, type, number, id, admissionType]) {
      let index = state.tabsData.findIndex((el) => el.name == name);
      if (type == "remove") {
        state.tabsData.splice(index, 1);
      } else if (type == "add") {
        if (index != -1) return;
        state.tabsData.splice(number, 0, {
          name: name,
          id: id,
          type: admissionType,
          active: false,
        });
      }
    },
    UPDATE_TABS_DATA(state, [name, disabled]) {
      let index = state.tabsData.findIndex((el) => el.name == name);
      state.tabsData.forEach((element, i) => {
        if (disabled) {
          state.tabsData[i].active = true;
          return;
        }
        if (i <= index) {
          state.tabsData[i].active = true;
        } else {
          state.tabsData[i].active = false;
        }
      });
    },
    UPDATE_APPLICANT_FORM(state, [link, data, type]) {
      let obj = {};
      obj = { ...obj, ...state.dataForm };
      if (link) {
        obj[link] = {};
        if (type == "init") {
          obj[link] = data;
        } else if (type == "object") {
          obj[link] = { ...obj[link], ...data, ...state.dataForm[link] };
        } else {
          obj[link] = { ...obj[link], ...state.dataForm[link] };
        }
        if(data && data.name){
          obj[link][data.name] = data.value;
        }
      }
      state.dataForm = obj;
    },
    UPDATE_PREVIEW(state, data) {
      state.preview = data;
    },
    SET_LOAD(state, data) {
      state.load = data;
    },
    SET_LOOKUPS(state, data) {
      state.lookups = data;
    },
    SET_DATA(state, data) {
      state.item = data;
    },
    SET_NEED_TRANING(state, data) {
      state.needTraning = data;
    },
    SET_ADMISSION_DATA(state, data) {
      state.admissionData = data;
    },
    SET_LOAD(state, data) {
      let obj = {};
      obj = { ...obj, ...state.load };
      obj[data.link] = data.data;
      state.load = obj;
    },
  },
  actions: {
    admissionData({ commit }, payload) {
      commit("SET_LOAD", true);
      return new Promise((resolve, reject) => {
        admission
          .admissionData(payload)
          .then((response) => {
            commit("SET_ADMISSION_DATA", response);
            commit("SET_LOAD", false);
            resolve(response)
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    print({ commit }, payload) {
      commit("SET_LOAD", { link: "apply", data: true });
      return new Promise((resolve, reject) => {
        admission
          .print(payload)
          .then((response) => {
            fileDownload(response, "Cash_Instructions.pdf");
            commit("SET_LOAD", { link: "apply", data: false });
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", { link: "apply", data: false });
          });
      });
    },
    getApplicant({ commit, state }, payload) {
      commit("SET_LOAD", { link: "basicData", data: true });
      return new Promise((resolve, reject) => {
        admission
          .getApplicant(payload)
          .then((response) => {
            commit("SET_DATA", response.data);
            commit("UPDATE_APPLICANT_FORM", [
              "applicantForm",
              response.data,
              "init",
            ]);
            commit("SET_LOAD", { link: "basicData", data: false });
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", { link: "basicData", data: false });
          });
      });
    },
    lookups({ commit }, payload) {
      if (!payload) return;
      commit("SET_LOAD", true);
      return new Promise((resolve, reject) => {
        admission
          .lookups(payload)
          .then((response) => {
            commit("SET_LOOKUPS", response.data || response.success);
            resolve(response);
            commit("SET_LOAD", false);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    needTraning({ commit }, payload) {
      commit("SET_LOAD", { link: "training", data: true });
      return new Promise((resolve, reject) => {
        admission
          .needTraning(payload)
          .then((response) => {
            resolve(response);
            commit("SET_NEED_TRANING", response);
            commit("SET_LOAD", { link: "training", data: false });
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", { link: "training", data: false });
          });
      });
    },
    pay({ commit }, payload) {
      commit("SET_LOAD", true);
      return new Promise((resolve, reject) => {
        admission
          .pay(payload.id, payload.query)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve(response.data);
          })
          .catch((error) => {
            commit("SET_LOAD", false);
            reject(error);
          });
      });
    },
    createTransaction({ commit }, payload) {
      commit("SET_LOAD", true);
      return new Promise((resolve, reject) => {
        admission
          .createTransaction(payload)
          .then((response) => {
            resolve(response);
            commit("SET_LOAD", false);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    checkBankMisrPaymentStatus({ commit }, payload) {
      commit("SET_LOAD", true);
      return new Promise((resolve, reject) => {
        admission
          .checkBankMisrPaymentStatus(payload)
          .then((response) => {
            resolve(response);
            commit("SET_LOAD", false);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    save({ commit }, payload) {
      if (payload.last) {
        commit("SET_LOAD", { link: "save", data: true });
      }
      return new Promise((resolve, reject) => {
        admission
          .save(payload)
          .then((response) => {
            resolve(response);
            commit("SET_LOAD", { link: "save", data: false });
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", { link: "save", data: false });
          });
      });
    },
    verifiedEmail({ commit }, payload) {
      commit("SET_LOAD", { link: "apply", data: true });
      return new Promise((resolve, reject) => {
        admission
          .verifiedEmail(payload)
          .then((response) => {
            resolve(response);
            commit("SET_LOAD", { link: "apply", data: false });
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", { link: "apply", data: false });
          });
      });
    },
  },
};

export default admissionModule;
